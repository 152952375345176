<template>

	<svg :class="class" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_681_25961)">
			<path d="M4.16699 12.5C3.39042 12.5 3.00214 12.5 2.69585 12.3732C2.28747 12.204 1.96302 11.8795 1.79386 11.4712C1.66699 11.1649 1.66699 10.7766 1.66699 10V4.33335C1.66699 3.39993 1.66699 2.93322 1.84865 2.5767C2.00844 2.2631 2.2634 2.00813 2.57701 1.84834C2.93353 1.66669 3.40024 1.66669 4.33366 1.66669H10.0003C10.7769 1.66669 11.1652 1.66669 11.4715 1.79355C11.8798 1.96271 12.2043 2.28717 12.3735 2.69555C12.5003 3.00183 12.5003 3.39012 12.5003 4.16669M10.167 18.3334H15.667C16.6004 18.3334 17.0671 18.3334 17.4236 18.1517C17.7372 17.9919 17.9922 17.7369 18.152 17.4233C18.3337 17.0668 18.3337 16.6001 18.3337 15.6667V10.1667C18.3337 9.23327 18.3337 8.76656 18.152 8.41004C17.9922 8.09643 17.7372 7.84147 17.4236 7.68168C17.0671 7.50002 16.6004 7.50002 15.667 7.50002H10.167C9.23357 7.50002 8.76686 7.50002 8.41034 7.68168C8.09674 7.84147 7.84177 8.09643 7.68198 8.41004C7.50033 8.76656 7.50033 9.23327 7.50033 10.1667V15.6667C7.50033 16.6001 7.50033 17.0668 7.68198 17.4233C7.84177 17.7369 8.09674 17.9919 8.41034 18.1517C8.76686 18.3334 9.23357 18.3334 10.167 18.3334Z" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
		</g>
		<defs>
			<clipPath id="clip0_681_25961">
				<rect width="24" height="24" fill="white"/>
			</clipPath>
		</defs>
	</svg>
	
</template>

<script>

	export default {

		props: ['class']

	};
	
</script>