<template>

	<svg :class="class" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M8.00004 3.33333V12.6667M3.33337 8H12.6667" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
	
</template>

<script>

	export default {

		props: ['class']

	};
	
</script>