<template>

	<svg :class="class" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="message-text-circle-01">
			<path id="Fill" opacity="0.12" d="M21.0001 11.5C21.0001 16.1944 17.1945 20 12.5001 20C11.4233 20 10.3933 19.7998 9.44527 19.4345C9.27193 19.3678 9.18527 19.3344 9.11635 19.3185C9.04856 19.3029 8.99949 19.2963 8.92997 19.2937C8.8593 19.291 8.78176 19.299 8.62668 19.315L3.50565 19.8444C3.01741 19.8948 2.77329 19.9201 2.62929 19.8322C2.50386 19.7557 2.41843 19.6279 2.39569 19.4828C2.36958 19.3161 2.48624 19.1002 2.71955 18.6684L4.35521 15.6408C4.48991 15.3915 4.55726 15.2668 4.58777 15.1469C4.61789 15.0286 4.62518 14.9432 4.61554 14.8214C4.60578 14.6981 4.55168 14.5376 4.44348 14.2166C4.15596 13.3636 4.00011 12.45 4.00011 11.5C4.00011 6.80558 7.80569 3 12.5001 3C17.1945 3 21.0001 6.80558 21.0001 11.5Z" fill="#6645DE"/>
			<path id="Icon" d="M7.99999 9.5H12M7.99999 13H15M12.5 20C17.1944 20 21 16.1944 21 11.5C21 6.80558 17.1944 3 12.5 3C7.80557 3 3.99999 6.80558 3.99999 11.5C3.99999 12.45 4.15584 13.3636 4.44336 14.2166C4.55156 14.5376 4.60566 14.6981 4.61542 14.8214C4.62506 14.9432 4.61777 15.0286 4.58764 15.1469C4.55714 15.2668 4.48979 15.3915 4.35508 15.6408L2.71943 18.6684C2.48612 19.1002 2.36946 19.3161 2.39557 19.4828C2.41831 19.6279 2.50374 19.7557 2.62917 19.8322C2.77317 19.9201 3.01729 19.8948 3.50553 19.8444L8.62655 19.315C8.78164 19.299 8.85918 19.291 8.92985 19.2937C8.99936 19.2963 9.04844 19.3029 9.11622 19.3185C9.18514 19.3344 9.27181 19.3678 9.44515 19.4345C10.3932 19.7998 11.4232 20 12.5 20Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
		</g>
	</svg>
	
</template>

<script>

	export default {

		props: ['class']

	};
	
</script>