<template>

	<svg :class="class" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M19 10L5.00003 10M19 10L20.8642 13.1069C21.282 13.8032 21.4909 14.1514 21.5993 14.5262C21.6955 14.8582 21.7335 15.2045 21.7117 15.5495C21.6871 15.9389 21.5587 16.324 21.3019 17.0944L21.0941 17.7179C20.7007 18.8981 20.5039 19.4882 20.1391 19.9245C19.8169 20.3098 19.4032 20.608 18.9358 20.7918C18.4065 21 17.7845 21 16.5404 21L7.45967 21C6.2156 21 5.59356 21 5.06429 20.7918C4.5969 20.608 4.18315 20.3098 3.86096 19.9245C3.49611 19.4882 3.2994 18.8981 2.90599 17.7179L2.69816 17.0944C2.44137 16.324 2.31298 15.9389 2.28838 15.5495C2.26658 15.2044 2.30458 14.8582 2.40071 14.5261C2.5092 14.1514 2.71809 13.8032 3.13587 13.1069L5.00003 10M19 10L19.4173 8.53955C19.6151 7.84717 19.714 7.50097 19.6363 7.22708C19.5683 6.98715 19.413 6.78132 19.201 6.64996C18.959 6.5 18.599 6.5 17.8789 6.5L6.1212 6.5C5.4011 6.5 5.04105 6.5 4.79904 6.64996C4.58705 6.78132 4.43179 6.98715 4.36372 7.22708C4.28602 7.50097 4.38493 7.84717 4.58276 8.53955L5.00003 10M12 6.5H8.46431C7.94335 6.5 7.44373 6.28929 7.07535 5.91421C6.70698 5.53914 6.50003 5.03043 6.50003 4.5C6.50003 3.96957 6.70698 3.46086 7.07535 3.08579C7.44373 2.71071 7.94335 2.5 8.46431 2.5C11.2143 2.5 12 6.5 12 6.5ZM12 6.5H15.5357C16.0567 6.5 16.5563 6.28929 16.9247 5.91421C17.2931 5.53914 17.5 5.03043 17.5 4.5C17.5 3.96957 17.2931 3.46086 16.9247 3.08579C16.5563 2.71071 16.0567 2.5 15.5357 2.5C12.7857 2.5 12 6.5 12 6.5Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
	
</template>

<script>

	export default {

		props: ['class']

	};
	
</script>