<template>

	<svg :class="class" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M12 4L4 12M4 4L12 12" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>

</template>

<script>

	export default {

		props: ['class'],

	};
	
</script>