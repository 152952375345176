<template>
	
	<div>
		<component :class="cssClass" :is="type"></component>
	</div>

</template>

<script>

	import Plus from './icons/Plus.vue'
	import CheckCircle from './icons/CheckCircle.vue'
	import Check from './icons/Check.vue'
	import Edit from './icons/Edit.vue'
	import Close from './icons/Close.vue'
	import Email from './icons/Email.vue'
	import Calendar from './icons/Calendar.vue'
	import ArrowLeft from './icons/ArrowLeft.vue'
	import ArrowRight from './icons/ArrowRight.vue'
	import Camera from './icons/Camera.vue'
	import Download from './icons/Download.vue'
	import User from './icons/User.vue'
	import InfoCircle from './icons/InfoCircle.vue'
	import Bank from './icons/Bank.vue'
	import UserTimes from './icons/UserTimes.vue'
	import UserCheck from './icons/UserCheck.vue'
	import FaceIdSquare from './icons/FaceIdSquare.vue'
	import Flag from './icons/Flag.vue'
	import SlashCircle from './icons/SlashCircle.vue'
	import Box from './icons/Box.vue'
	import Image from './icons/Image.vue'
	import CalendarCheck from './icons/CalendarCheck.vue'
	import ClockCheck from './icons/ClockCheck.vue'
	import ClockRefresh from './icons/ClockRefresh.vue'
	import FaceSad from './icons/FaceSad.vue'
	import ShoppingBag from './icons/ShoppingBag.vue'
	import Smile from './icons/Smile.vue'
	import TimesCircle from './icons/TimesCircle.vue'
	import Clock from './icons/Clock.vue'
	import Eye from './icons/Eye.vue'
	import EyeOff from './icons/EyeOff.vue'
	import Lock from './icons/Lock.vue'
	import Search from './icons/Search.vue'
	import HelpCircle from './icons/HelpCircle.vue'
	import AngleDown from './icons/AngleDown.vue'
	import AngleUp from './icons/AngleUp.vue'
	import LinkExternal from './icons/LinkExternal.vue'
	import ArrowBlockRight from './icons/ArrowBlockRight.vue'
	import MessageTextCircle from './icons/MessageTextCircle.vue'
	import Copy from './icons/Copy.vue'
	import HeartHexagon from './icons/HeartHexagon.vue'
	import UserEdit from './icons/UserEdit.vue'
	import Settings from './icons/Settings.vue'
	import Refresh from './icons/Refresh.vue'
	import AlignTop from './icons/AlignTop.vue'
	import AlignBottom from './icons/AlignBottom.vue'
	import Star from './icons/Star.vue'
	import Pot from './icons/Pot.vue'
	import Pencil from './icons/Pencil.vue'
	import PenTool from './icons/PenTool.vue'
	import DotsVertical from './icons/DotsVertical.vue'
	import RotateCw from './icons/RotateCw.vue'
	import AlertCircle from './icons/AlertCircle.vue'
	import Wallet from './icons/Wallet.vue'
	import Printer from './icons/Printer.vue'
	import Minus from './icons/Minus.vue'
	import MessageSmileSquare from './icons/MessageSmileSquare.vue'
	
	export default {

		props: ['class', 'size', 'type'],

		components: {
			'plus': Plus,
			'check-circle': CheckCircle,
			'edit': Edit,
			'check': Check,
			'close': Close,
			'email': Email,
			'calendar': Calendar,
			'arrow-left': ArrowLeft,
			'arrow-right': ArrowRight,
			'camera': Camera,
			'download': Download,
			'user': User,
			'info-circle': InfoCircle,
			'bank': Bank,
			'user-times': UserTimes,
			'user-check': UserCheck,
			'face-id-square': FaceIdSquare,
			'flag': Flag,
			'slash-circle': SlashCircle,
			'box': Box,
			'file-image': Image,
			'calendar-check': CalendarCheck,
			'clock-check': ClockCheck,
			'clock-refresh': ClockRefresh,
			'face-sad': FaceSad,
			'shopping-bag': ShoppingBag,
			'smile': Smile,
			'times-circle': TimesCircle,
			'clock': Clock,
			'eye': Eye,
			'eye-off': EyeOff,
			'lock': Lock,
			'search': Search,
			'help-circle': HelpCircle,
			'angle-down': AngleDown,
			'angle-up': AngleUp,
			'link-external': LinkExternal,
			'arrow-block-right': ArrowBlockRight,
			'message-text-circle': MessageTextCircle,
			'copy': Copy,
			'user-edit': UserEdit,
			'settings': Settings,
			'heart-hexagon': HeartHexagon,
			'refresh': Refresh,
			'align-top': AlignTop,
			'align-bottom': AlignBottom,
			'star': Star,
			'pot': Pot,
			'pencil': Pencil,
			'pen-tool': PenTool,
			'dots-vertical': DotsVertical,
			'rotate-cw': RotateCw,
			'alert-circle': AlertCircle,
			'wallet': Wallet,
			'printer': Printer,
			'minus': Minus,
			'message-smile-square': MessageSmileSquare,
		},

		computed: {

			cssClass() {
				let cssClass = ''
				if( ! this.size ) {
					cssClass = 'h-6 w-6'
				} else {
					switch( this.size ) {
						case "xs":
							cssClass = 'h-2.5 w-2.5'
							break

						case "sm":
							cssClass = 'h-4 w-4'
							break

						case "md":
							cssClass = 'h-5 w-5'
							break

						case "lg:lg":
							cssClass = 'h-6 w-6 lg:h-8 lg:w-8'
							break;

						case "lg":
							cssClass = 'h-8 w-8'
							break

						case "xl":
							cssClass = 'h-12 w-12'
							break

						case "2-xl":
							cssClass = 'h-16 w-16'
							break

						case "3-xl":
							cssClass = 'h-20 w-20'
							break

						case "4-xl":
							cssClass = 'h-24 w-24'
							break

						case "5-xl":
							cssClass = 'h-28 w-28'
							break

						default:
							cssClass = "h-6 w-6"
							break
					}
				}
				return `${cssClass} ${this.class}`
			},
		}

	};

</script>